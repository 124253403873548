<template>
  <div>
    <section class="bg-dark">
      <b-container>
        <b-row>
          <b-col cols="12" md="12" lg="12">
            <div
              style="
                width: 100%;
                height: 60px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
              "
            >
              <h4 class="mt-0 mb-0"><strong>LOKER GRINATA</strong></h4>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <!-- <section class="mt-5">
      <b-container>
        <b-row>
          <b-col cols="12" md="12" lg="12">
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <div class="alert alert-warning" role="alert">
                  <h4 class="alert-heading">Well done!</h4>
                  <p>
                    Aww yeah, you successfully read this important alert
                    message. This example text is going to run a bit longer so
                    that you can see how spacing within an alert works with this
                    kind of content.
                  </p>
                  <hr />
                  <p class="mb-0">
                    Whenever you need to, be sure to use margin utilities to
                    keep things nice and tidy.
                  </p>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </section> -->

    <section class="mt-5">
      <b-container>
        <b-row>
          <b-col cols="12" md="3" lg="3">
            <div
              style="
                width: 100%;
                padding: 15px;
                border-radius: 10px;
                background-color: #fff;
              "
              class="shadow-lg"
            >
              <b-row>
                <b-col cols="12" md="12" lg="12">
                  <h5><strong>PENCARIAN LOKER</strong></h5>
                  <hr />
                </b-col>
                <b-col cols="12" md="12" lg="12">
                  <b-form>
                    <!-- <b-form-group label="Divisi">
                      <multiselect
                        :options="divisi"
                        :multiple="false"
                        :searchable="true"
                        :close-on-select="true"
                        :show-labels="false"
                        placeholder="-- Pilih Divisi --"
                      ></multiselect>
                    </b-form-group> -->

                    <b-form-group label="Posisi">
                      <multiselect
                        v-model="posisiId"
                        :options="posisi"
                        :multiple="false"
                        :searchable="true"
                        :close-on-select="true"
                        :show-labels="false"
                        label="namaPosisi"
                        track-by="masterPosisiId"
                        @input="itikiwir(posisiId)"
                        placeholder="-- Pilih Posisi --"
                      ></multiselect>
                    </b-form-group>
                  </b-form>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  ><b-button
                    @click="getData(), (posisiId = '')"
                    variant="secondary"
                    block
                    >Reset</b-button
                  ></b-col
                >
                <b-col
                  ><b-button @click="search" variant="primary" block
                    >Cari</b-button
                  ></b-col
                >
              </b-row>
            </div>
          </b-col>

          <b-col cols="12" md="9" lg="9">
            <div
              v-for="item in items"
              :key="item.id"
              style="
                width: 100%;
                padding: 15px;
                border-radius: 10px;
                background-color: #fff;
              "
              class="shadow-lg mb-3"
            >
              <b-row>
                <b-col cols="3" md="3" lg="3">
                  <img
                    v-if="!item.poster"
                    src="https://picsum.photos/200/200/?image=20"
                    alt=""
                    style="width: 100%"
                  />
                  <a v-else :href="item.src1"
                    ><img :src="item.src1" alt="" style="width: 100%"
                  /></a>

                  <b-button
                    variant="primary"
                    block
                    class="mt-3"
                    v-b-modal.modalDaftar
                    @click="(lokerData = item), getKebutuhan(item)"
                    ><h6 class="mt-0 mb-0">Daftar Lowongan Kerja</h6></b-button
                  >
                </b-col>
                <b-col cols="9" md="9" lg="9">
                  <b-row>
                    <b-col cols="6" md="7" lg="7"
                      ><h4>
                        <strong>{{ item.namaLoker }}</strong>
                      </h4></b-col
                    >
                    <!-- <b-col cols="6" md="5" lg="5"
                      ><b-badge variant="danger" class="float-right"
                        ><h6 class="mt-0 mb-0 pl-2 pr-2">
                          {{ item.tanggalBerakhir }}
                        </h6></b-badge
                      ></b-col
                    > -->
                  </b-row>

                  <b-row>
                    <b-col cols="12" md="12" lg="12">
                      <b-badge variant="success"
                        ><h6 class="mt-0 mb-0 pl-2 pr-2">
                          {{ item.namaPosisi }}
                        </h6></b-badge
                      >
                    </b-col>
                  </b-row>

                  <b-row class="mt-3">
                    <b-col cols="12" md="12" lg="12">
                      <div
                        class="ql-editor"
                        v-html="item.keteranganLoker"
                      ></div>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="12" md="12" lg="12"> </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <!-- modal daftar -->
    <b-modal id="modalDaftar" centered>
      <template #modal-title>
        DAFTAR LOWONGAN KERJA <br />
        {{ lokerData.posisiDilamar }} <br />
        Umur {{ lokerData.umurMinimal }} s/d {{ lokerData.umurMaksimal }}
      </template>
      <b-form>
        <b-form-group label="Nama Lengkap" label-cols-md="3">
          <b-form-input
            type="text"
            :state="checkIfValid('namaPengirim')"
            v-model="$v.dataPelamar.namaPengirim.$model"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Nomor KTP" label-cols-md="3">
          <b-form-input
            type="number"
            :state="checkIfValid('nomorKTPPengirim')"
            v-model="$v.dataPelamar.nomorKTPPengirim.$model"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Nomor Telepon" label-cols-md="3">
          <b-form-input
            type="number"
            :state="checkIfValid('nomorTeleponPengirim')"
            v-model="$v.dataPelamar.nomorTeleponPengirim.$model"
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Tanggal Lahir" label-cols-md="3">
          <date-picker
            style="width: 100%"
            :state="checkIfValid('tanggalLahirPengirim')"
            v-model="$v.dataPelamar.tanggalLahirPengirim.$model"
            @change="cekUmur(dataPelamar.tanggalLahirPengirim)"
          ></date-picker>
        </b-form-group>

        <b-form-group label="Alamat" label-cols-md="3">
          <b-form-textarea
            type="text"
            :state="checkIfValid('alamatPengirim')"
            v-model="$v.dataPelamar.alamatPengirim.$model"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group label="Email" label-cols-md="3">
          <b-form-input
            type="text"
            :state="checkIfValid('emailPengirim')"
            v-model="$v.dataPelamar.emailPengirim.$model"
            @change="itikiwir(dataPelamar.emailPengirim)"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          label-cols-md="3"
          v-for="(item, idx) in dataPelamar.files"
          :key="idx"
        >
          <template v-slot:label> {{ item.namaKebutuhan }} </template>
          <b-row>
            <b-col
              ><b-button
                variant="primary"
                class="mr-1"
                @click="selectFile(), (index = idx)"
                >Upload File</b-button
              ></b-col
            >
            <b-col>
              <b-form-checkbox
                v-model="item.statusUpload"
                disabled
                value="1"
                unchecked-value="0"
              >
              </b-form-checkbox
            ></b-col>
          </b-row>
        </b-form-group>

        <span v-if="show" class="text-danger font-italic font-weight-light">
          <center>
            <h4>{{ msg }}</h4>
          </center>
        </span>

        <b-form-file
          class="inputan"
          ref="file1"
          type="file"
          @input="handleFile()"
        ></b-form-file>
      </b-form>
      <template #modal-footer>
        <b-button
          variant="secondary"
          @click="$bvModal.hide('modalDaftar'), resets()"
        >
          Batal
        </b-button>
        <b-button
          variant="primary"
          :disabled="busy || !isValid || show || umur"
          @click="simpan()"
        >
          {{ button }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend.js";
import Multiselect from "vue-multiselect";
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  maxLength,
  email,
} from "vuelidate/lib/validators";
import "vue-multiselect/dist/vue-multiselect.min.css";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
import "moment/locale/id";
export default {
  components: {
    Multiselect,
    DatePicker,
  },
  data() {
    return {
      moment,
      dataPelamar: {
        namaPengirim: "",
        nomorKTPPengirim: "",
        nomorTeleponPengirim: "",
        alamatPengirim: "",
        emailPengirim: "",
        tanggalLahirPengirim: "",
        lokerId: "",
        statusPostLoker: 0,
        files: [],
      },
      show: false,
      umur: false,
      file1: "",
      items: [],
      msg: "",
      // divisi: [],
      posisi: [],
      posisiId: "",
      lokerData: "",
      busy: false,
      button: "Simpan",
      src1: "",
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.dataPelamar, null, 4);
    },
    isValid() {
      return !this.$v.dataPelamar.$invalid;
    },
    isDirty() {
      return this.$v.dataPelamar.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    dataPelamar: {
      namaPengirim: {
        required,
      },
      nomorKTPPengirim: {
        required,
        minLength: minLength(16),
        maxLength: maxLength(16),
      },
      nomorTeleponPengirim: {
        required,
      },
      tanggalLahirPengirim: {
        required,
      },
      alamatPengirim: {
        required,
      },
      emailPengirim: {
        required,
        email,
      },
    },
  },
  mounted() {
    // this.getPosisi();
    this.getData();
  },
  methods: {
    itikiwir(x) {
      // console.log(x);
    },
    selectFile() {
      let fileInputElement = this.$refs.file1;
      fileInputElement.$refs.input.click();
    },
    handleFile() {
      this.dataPelamar.files[this.index].file1 = this.$refs.file1.files[0];

      // console.log(this.$refs.file1.files[0].name, "iki");
      if (this.dataPelamar.files[this.index].file1 != this.file1) {
        this.dataPelamar.files[this.index].statusUpload = 1;
      }
      this.file1 = this.$refs.file1.files[0];
      // this.$refs.file1.files = []
      // console.log(this.$refs.file1.files[0].name, "iki2");
    },
    cekUmur(x) {
      let vm = this;
      if (x == "") {
        vm.busy = false;
      } else {
        let umurs = vm.moment(x)
        let now = vm.moment()
        let umur = now.diff(umurs, 'years')
        if (
          umur > vm.lokerData.umurMaksimal ||
          umur < vm.lokerData.umurMinimal
        ) {
          console.log(umur,'false');
          vm.msg = "maaf umur anda tidak sesuai";
          vm.show = true;
          vm.umur = true;
          setTimeout(() => {
            vm.show = false;
          }, 4000);
        } else {
          console.log(umur, 'true');
          vm.umur = false;
          vm.msg = "";
          vm.show = false;
        }
      }
    },
    checkIfValid(fieldName) {
      const field = this.$v.dataPelamar[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    async getData() {
      let vm = this;
      let listLoker = await axios.get(ipBackend + "loker/listLokerTerbaru");
      // console.log(listLoker.data.data, "loker");
      vm.items = listLoker.data.data;
      for (let i = 0; i < vm.items.length; i++) {
        let x = vm.items[i];
        x.no = i + 1;
        x.tanggalBerakhir = moment(x.tanggalAkhirLoker).format("LL");
        x.posisiDilamar = `${x.namaLoker} - (Posisi : ${x.namaPosisi})`;
        this.posisi.push({
          namaPosisi: x.namaPosisi,
          masterPosisiId: x.masterPosisiId,
        });
        x.src1 = `${ipBackend}asset/file/${x.poster}`;
        // x.view = window.open(`${ipBackend}asset/file/${x.poster}`);
      }
    },
    async getKebutuhan(x) {
      let vm = this;
      let listKebutuhan = await axios.get(
        ipBackend + "loker/listByKebutuhanLokerId/" + x.lokerId
      );
      vm.dataPelamar.files = listKebutuhan.data.data.map((item, idx) => {
        return {
          ...item,
          file1: "",
          lokerId: item.idLoker,
          field: `file${idx + 1}`,
          statusUpload: 0,
        };
      });
    },
    resets() {
      this.dataPelamar = {
        namaPengirim: "",
        nomorKTPPengirim: "",
        nomorTeleponPengirim: "",
        alamatPengirim: "",
        emailPengirim: "",
        tanggalLahirPengirim: "",
        lokerId: "",
        statusPostLoker: 0,
        files: [],
      };
      this.busy = false;
      this.msg = "";
      this.$v.$reset();
    },
    search() {
      let vm = this;
      axios
        .post(ipBackend + "loker/searchLoker", {
          masterPosisiId: vm.posisiId.masterPosisiId,
        })
        .then((res) => {
          vm.items = res.data.data;
          for (let i = 0; i < this.items.length; i++) {
            let x = this.items[i];
            x.no = i + 1;
            x.tanggalBerakhir = moment(x.tanggalAkhirLoker).format("LL");
            x.posisiDilamar = `${x.namaLoker} - (Posisi : ${x.namaPosisi})`;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    simpan() {
      let vm = this;
      vm.busy = true;
      vm.cekUmur(vm.dataPelamar.tanggalLahirPengirim);
      if (vm.isValid || !vm.show) {
        vm.button = "Mohon Tunggu";
        vm.dataPelamar.lokerId = vm.lokerData.lokerId;
        let formData = new FormData();
        if (vm.dataPelamar.files.length > 0) {
          for (let i = 0; i < vm.dataPelamar.files.length; i++) {
            formData.append(`file${i + 1}`, vm.dataPelamar.files[i].file1);
          }
        }
        formData.append("namaPengirim", vm.dataPelamar.namaPengirim);
        formData.append("nomorKTPPengirim", vm.dataPelamar.nomorKTPPengirim);
        formData.append(
          "nomorTeleponPengirim",
          vm.dataPelamar.nomorTeleponPengirim
        );
        formData.append(
          "tanggalLahirPengirim",
          vm.dataPelamar.tanggalLahirPengirim
        );
        formData.append("alamatPengirim", vm.dataPelamar.alamatPengirim);
        formData.append("emailPengirim", vm.dataPelamar.emailPengirim);
        formData.append("lokerId", vm.dataPelamar.lokerId);
        formData.append("statusPostLoker", vm.dataPelamar.statusPostLoker);
        formData.append("bulk", JSON.stringify(vm.dataPelamar.files));
        axios
          .post(ipBackend + "postLoker/register2", formData)
          .then((res) => {
            console.log(res.data.message);
            if (res.data.message == "sukses") {
              vm.button = "Simpan";
              vm.busy = false;
              vm.dataPelamar.namaPengirim = "";
              vm.dataPelamar.alamatPengirim = "";
              vm.dataPelamar.emailPengirim = "";
              vm.dataPelamar.tanggalLahirPengirim = "";
              vm.dataPelamar.nomorKTPPengirim = "";
              vm.dataPelamar.nomorTeleponPengirim = "";
              vm.dataPelamar.files = [];
              vm.$bvModal.hide("modalDaftar");
              vm.$v.$reset();
            } else {
              vm.msg = res.data.message.toUpperCase();
              vm.button = "Simpan";
              vm.busy = false;
              vm.show = true;
              setTimeout(() => {
                vm.show = false;
              }, 4000);
            }
          })
          .catch((err) => {
            vm.msg = err;
            vm.button = "Simpan";
            vm.busy = false;
            vm.show = true;
            setTimeout(() => {
              vm.show = false;
            }, 4000);
          });
      }
    },
  },
};
</script>

<style scoped>
.inputan {
  visibility: hidden;
}
</style>
